import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrganizationsList() {
  // Use toast
  const toast = useToast()

  const refOrganizationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'credits', sortable: true },
    { key: 'allowedOperationTypes', sortable: true },
   
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrganizationListTable.value ? refOrganizationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refOrganizationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchOrganizations = (ctx, callback) => {
    store
      .dispatch('app-organization/fetchOrganizations', {
        name: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const organizations = response.data.results;
        callback(organizations)
        totalOrganizations.value = response.data.totalResults;

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organizations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  


  return {
    fetchOrganizations,
    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrganizationListTable,

   
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter
  }
}
