<template>
  <b-sidebar
    id="add-new-organization-sidebar"
    :visible="isAddNewOrganizationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-organization-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Organization
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >


          <!-- organization name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="organizationData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        

          <!-- Credits -->
          <validation-provider
            #default="validationContext"
            name="Credits"
            rules="required"
          >
            <b-form-group
              label="Credits"
              label-for="credits"
            >
              <b-form-input
                id="credits"
                type="number"
                v-model="organizationData.credits"
                :state="getValidationState(validationContext)"
                
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Operation Types -->
          <validation-provider
            #default="validationContext"
            name="Allowed Operation Types"
            rules="required"
          >
            <b-form-group
              label="Allowed Operation Types"
              label-for="allowed-operation-types"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="organizationData.allowedOperationTypes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="operationTypesOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="allowed-operation-types"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewOrganizationSidebarActive',
    event: 'update:is-add-new-organization-sidebar-active',
  },
  props: {
    isAddNewOrganizationSidebarActive: {
      type: Boolean,
      required: true,
    },
    operationTypesOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email
    }
  },
  setup(props, { emit }) {
    const blankOrganizationData = {
      name: '',
      credits: null,
      allowedOperationTypes: null,
  
    }

    const organizationData = ref(JSON.parse(JSON.stringify(blankOrganizationData)))
    const resetorganizationData = () => {
      organizationData.value = JSON.parse(JSON.stringify(blankOrganizationData))
    }

    const onSubmit = () => {
      organizationData.value.credits = parseInt(organizationData.value.credits);
      store.dispatch('app-organization/addOrganization', organizationData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-organization-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetorganizationData)

    return {
      organizationData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organization-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
